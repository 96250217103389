import React from 'react'

function NoInternet() {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 100,
            fontSize: 30
        }}>
            No Internet Connection :(
        </div>
    )
}

export default NoInternet
