import React from 'react'
import './custom.css';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Paper from '@material-ui/core/Paper';
import { notify } from 'react-notify-toast';
import { authenticationService } from '../../services/authentication'
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import { Modal } from '@material-ui/core';
import Modal from 'react-modal'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        backgroundColor: 'rgb(33, 25, 25/ 75%)'
    }
};





export default function ViewBooking() {
    const [isLoad, setIsLoad] = React.useState(false)
    const [bookings, setBookings] = React.useState([])
    const [slot, setSlot] = React.useState()
    const [availableSlots, setAvailableSlots] = React.useState([])
    const [isOpen, setIsOpen] = React.useState(false);
    const [deleteBookingId, setDeleteBookingId] = React.useState();
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [selectedSlot, setSelectedSlot] = React.useState()
    const [selectedBooking, setSelectedBooking] = React.useState({})
    const [bookingMode, setBookingModeType] = React.useState('ON_THE_SPOT')

    const [male, setMale] = React.useState(0)
    const [female, setFemale] = React.useState(0)
    const [children, setChildren] = React.useState(0)
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [name, setName] = React.useState('')
    const [idProofType, setIdProofType] = React.useState()
    const [idCardNumber, setIdCardNumber] = React.useState("")
    const [city, setCity] = React.useState("")
    const [date, setDate] = React.useState(new Date())

    const [slotIdError, setSlotIdError] = React.useState(false)
    const [maleError, setMaleError] = React.useState(false)
    const [femaleError, setFemaleError] = React.useState(false)
    const [childrenError, setChildrenError] = React.useState(false)
    const [phoneNumberError, setPhoneNumberError] = React.useState(false)
    const [nameError, setNameError] = React.useState(false)
    const [idProofTypeError, setIdProofTypeError] = React.useState(false)
    const [idCardNumberError, setIdCardNumberError] = React.useState(false)
    const [cityError, setCityError] = React.useState(false)
    const [isDownloading, setIsDownloading] = React.useState(false);


    const [highlightDate, setHighlightDate] = React.useState([
        {
            "unavailable_date_style": []
        },
        {
            "not_applicable_date_style": []
        },
        {
            "available_date_style": []
        }
    ])
    React.useEffect(() => {
        setIsLoad(true)
        const slotId = window.location.pathname.split('/')[3];
        if (slotId) {
            return authenticationService.fetchBooking(slotId).then(
                async (result) => {
                    setBookings(result.bookings)
                    setSlot(result.slot)
                    let startDate = new Date(parseFloat(result.slot.fromTime))
                    let endDate = moment(startDate).add(6, 'months').toDate();
                    await handleDateChange(startDate)
                    await fetchHeighlightdate(startDate, endDate)
                    setIsLoad(false)
                }).catch((error) => {
                    console.log(error)
                    setIsLoad(false)
                    if (error == "TypeError: Failed to fetch") {
                        error = 'Internal server error'
                    }
                    notify.show(error, 'custom', 5000, {
                        background: '#F55A4E', text: "#FFFFFF"
                    });
                    console.log('error', error)
                })
        }
    }, [])

    const fetchHeighlightdate = (startDate, endDate) => {
        return authenticationService.fetchDateStatus(startDate, endDate).then(
            async (result) => {
                let obj = result.response

                let not_applicable_date_arr = highlightDate.find(o => o.not_applicable_date_style)
                let available_date_arr = highlightDate.find(o => o.available_date_style)
                let unavailable_date_arr = highlightDate.find(o => o.unavailable_date_style)

                // grey
                let naDates = obj.UNAVAILABLE;
                naDates.map(item => {
                    let date = new Date(parseFloat(item))
                    console.log(date)
                    not_applicable_date_arr.not_applicable_date_style.push(date)
                })

                // green
                let remainingDates = obj.REMAINING;
                remainingDates.map(item => {
                    let date = new Date(parseFloat(item))
                    console.log(date)
                    available_date_arr.available_date_style.push(date)
                })

                // red
                let unavailableDates = obj.FULL;
                unavailableDates.map(item => {
                    let date = new Date(parseFloat(item))
                    console.log(date)
                    unavailable_date_arr.unavailable_date_style.push(date)
                })

                setHighlightDate([not_applicable_date_arr, available_date_arr, unavailable_date_arr])
                setIsLoad(false)
            }).catch((error) => {
                console.log(error)
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }

    const handleDateChange = (date) => {
        debugger
        setIsLoad(true)
        setDate(date)
        selectedBooking.date = date;
        return authenticationService.fetchSlotByDate(date, date).then(
            (result) => {
                console.log('result.slots', result.slots)
                if (result.slots != {}) {
                    let key = Object.keys(result.slots)[0];
                    setAvailableSlots(result.slots[key])
                } else {
                    setAvailableSlots([])
                }
                setIsLoad(false)

            }).catch((error) => {
                console.log(error)
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
            })
    };

    const editBooking = () => {
        // Call the update booking

        let isFormError = false
        // if (!(selectedBooking.slot.id > 0)) {
        //     isFormError = true;
        //     setSlotIdError(true);
        // }
        if (selectedBooking.phoneNumber == "") {
            isFormError = true
            setPhoneNumberError(true)
        }
        if (selectedBooking.male.length == 0) {
            isFormError = true
            setMaleError(true)
        }
        if (selectedBooking.name == "") {
            isFormError = true
            setNameError(true)
        }
        if (selectedBooking.female.length == 0) {
            isFormError = true
            setFemaleError(true)
        }
        if (selectedBooking.children.length == 0) {
            isFormError = true
            setChildrenError(true)
        }
        if (selectedBooking.city == "") {
            isFormError = true
            setCityError(true)
        }
        if (selectedBooking.idCardNumber == "") {
            isFormError = true
            setIdCardNumberError(true)
        }
        if (isFormError) {
            return
        }

        debugger;
        setIsLoad(true)
        // console.log(selectedBooking);
        return authenticationService.editBooking(
            selectedBooking.id,
            selectedBooking.bookedByPhoneNumber,
            selectedBooking.name,
            selectedBooking.idProofType,
            selectedBooking.idCardNumber,
            parseFloat(selectedBooking.children),
            parseFloat(selectedBooking.male),
            parseFloat(selectedBooking.female),
            selectedSlot ? selectedSlot : selectedBooking.slot.id,
            // selectedBooking.date,
            date,
            selectedBooking.city,
            bookingMode
        ).then(
            async (result) => {
                debugger
                notify.show("Booking updated", "success")
                setIsLoad(false)
            }).catch((error) => {
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }

    const deleteBooking = (id) => {
        setIsLoad(true)
        return authenticationService.cancleBooking(id).then(
            (result) => {
                notify.show("Booking cancelled", "success")
                let updatedFutureBookings = bookings.filter(item => item.id != id)
                setBookings(updatedFutureBookings)
                setIsOpen(false)
                setIsLoad(false)
            }).catch((error) => {
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
            })
    }

    const ExampleCustomInput = ({ value, onClick }) => (
        <TextField
            id="standard-basic"
            label="Date (તારીખ)"
            style={{ width: '40%' }}
            value={value}
            variant="outlined"
            onClick={onClick}
            style={{ width: '100%' }}
        />
    );

    const downloadPDFAPI = (bookingId) => {
        debugger;
        return authenticationService.downloadPDF(
            bookingId
        ).then(
            async (result) => {
                debugger
                if (result.ticket) {
                    // const pdf = `data:application/pdf;base64,${result.ticket}`;
                    // debugger
                    // const linkSource = `${pdf}`;
                    // const downloadLink = document.createElement("a");
                    // const fileName = "Gatepass.pdf";

                    // downloadLink.href = linkSource;
                    // downloadLink.download = fileName;
                    // downloadLink.click();
                    setIsDownloading(false);
                    let pdfWindow = window.open("", '_blank')
                    pdfWindow.document.write(
                        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                        encodeURI(result.ticket) + "'></iframe>"
                    )
                }

            }).catch((error) => {
                setIsDownloading(false);
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }



    return (
        <>
            <React.Fragment>

                {/* LOADER */}
                <GridContainer align="center" style={{ paddingTop: isLoad ? 40 : 0 }}>
                    <GridItem xs={12} sm={4} md={4} lg={4}></GridItem>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                        {isLoad && <CircularProgress />}
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} lg={4} align="right"></GridItem>
                </GridContainer>

                <GridContainer align="center">
                    <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
                    <GridItem xs={0} sm={0} md={8} lg={8}>

                        {slot && <GridContainer align="left" style={{ paddingTop: 20 }}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={1}>
                                    <GridContainer align="left" style={{ padding: 10, paddingTop: 15 }}>
                                        <GridItem xs={12} sm={4} md={4} lg={4}>
                                            <p>Date: <span style={{ color: 'grey' }}>{moment(new Date(parseFloat(slot.fromTime))).format('DD MMM YYYY')}</span></p>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4} lg={4}>
                                            <p>
                                                <span style={{ marginLeft: 5, marginRight: 5 }}>Time slot:</span>
                                                <span style={{ color: 'grey' }}>{moment(new Date(parseFloat(slot.fromTime))).format('hh:mm A')}</span>
                                                <span style={{ marginLeft: 5, marginRight: 5, color: 'grey' }}>to</span>
                                                <span style={{ color: 'grey' }}>{moment(new Date(parseFloat(slot.toTime))).format('hh:mm A')}</span>
                                            </p>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4} lg={4}>
                                            <p>Capacity: <span style={{ color: 'grey' }}>{slot.limit - slot.totalSeatsBooked}/{slot.limit} left</span></p>
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            </GridItem>
                        </GridContainer>}

                        <GridContainer align="left" style={{ paddingTop: 20 }}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                {bookings.length > 0 ? bookings.map((item, index) => {
                                    return (
                                        <Paper elevation={1}>
                                            <div class="booking-wrap">
                                                <h3 style={{ marginTop: 0 }}>
                                                    <div className="row justify-content-between">
                                                        <div className="col-6 col-lg-6">
                                                            <p><span>Booking ID:</span> <span>{item.bookingId}</span>
                                                                <span
                                                                    onClick={() => {
                                                                        setIsDownloading(true);
                                                                        downloadPDFAPI(item.bookingId)
                                                                    }}
                                                                ><img src={require('../../assets/img/print.png')} style={{ marginLeft: 15, height: 30, width: 30 }} /></span>
                                                            </p>
                                                        </div>
                                                        <div className="col-6 col-lg-6 text-right">
                                                            <div className="update-details">
                                                                <a
                                                                    onClick={() => {
                                                                        debugger
                                                                        setOpenEditDialog(true)
                                                                        setDeleteBookingId(item.id)
                                                                        setSelectedBooking(item)
                                                                    }}
                                                                    style={{ color: '#393939', cursor: 'pointer' }}
                                                                    className="soft"><img src={require('../../assets/img/edit_icon.png')} /> Edit Booking</a>
                                                                <a
                                                                    onClick={() => {
                                                                        setIsOpen(true)
                                                                        setDeleteBookingId(item.id)
                                                                    }}
                                                                    style={{ color: 'red', cursor: 'pointer', marginLeft: 10 }}
                                                                    className="soft"><img src={require('../../assets/img/delete_icon.png')} /> Cancel Booking</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </h3>

                                                <div class="booking-detail">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_label light-label">Name:</span></p>
                                                        </div>
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_name actual-data">{item.name}</span></p>
                                                        </div>
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_label light-label">Mobile No:</span></p>
                                                        </div>
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_name actual-data">{item.bookedByPhoneNumber}</span></p>
                                                        </div>
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_label light-label">No. of Person:</span></p>
                                                        </div>
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_name actual-data">{item.personCount}</span></p>
                                                        </div>

                                                        {/* <div class="col-6 col-lg-2">
                                                            <p><span class="user_label light-label">Date of Birth:</span></p>
                                                        </div> */}
                                                        {/* <div class="col-6 col-lg-2">
                                                            <p><span class="user_name actual-data">{moment(parseFloat(item.dateOfBirth)).format('DD MMM YYYY')}</span></p>
                                                        </div> */}
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_label light-label">Booking Date:</span></p>
                                                        </div>
                                                        <div className="col-6 col-lg-2">
                                                            <p><span className="user_name actual-data">{moment(item.slot.fromTime, "x").format('DD MMM YYYY')}</span></p>
                                                        </div>
                                                        <div className="col-6 col-lg-2">
                                                            <p><span className="user_label light-label">Booking Time:</span></p>
                                                        </div>
                                                        <div className="col-6 col-lg-2">
                                                            <p><span className="user_name actual-data">{moment(item.slot.fromTime, "x").format('hh:mm A')}</span></p>
                                                        </div>
                                                        <div className="col-6 col-lg-2">
                                                            <p><span className="user_label light-label">City:</span></p>
                                                        </div>
                                                        <div className="col-6 col-lg-2">
                                                            <p><span className="user_name actual-data">{item.city}</span></p>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_label light-label">Photo ID Number:</span></p>
                                                        </div>
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_name actual-data">{item.idCardNumber}</span></p>
                                                        </div>
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_label light-label">Booking Mode:</span></p>
                                                        </div>
                                                        <div class="col-6 col-lg-2">
                                                            <p><span class="user_name actual-data">{item.bookingMode == "ON_THE_SPOT" ? "ON THE SPOT" : item.bookingMode}</span></p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </Paper>
                                    )
                                })
                                    :
                                    <Paper elevation={1} style={{ width: '100%' }}>
                                        <GridContainer align="left">
                                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                                <div style={{ height: 60 }} align="center">
                                                    <p style={{ marginTop: 30 }}>No Bookings Found ...</p>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </Paper>
                                }
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
                </GridContainer>

                <Dialog
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to cancel this booking?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            setIsOpen(false)
                            setDeleteBookingId()
                        }} color="primary">
                            Close
                        </Button>
                        <Button onClick={() => deleteBooking(deleteBookingId)} color="primary">
                            Cancel booking
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={() => setOpenEditDialog(false)}
                    aria-labelledby="customized-dialog-title"
                    open={openEditDialog}
                    maxWidth={'lg'}
                    fullWidth={true}
                >
                    <DialogTitle id="customized-dialog-title" onClose={() => setOpenEditDialog(false)}>
                        Edit Booking
                    </DialogTitle>
                    <DialogContent dividers>

                        <GridContainer align="left">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={0}>
                                    <h5>Personal Details (અંગત વિગતો)</h5>
                                    <Divider />
                                    <GridContainer align="left" style={{ paddingTop: 20 }}>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <TextField
                                                id="standard-basic"
                                                label="Name (નામ)"
                                                style={{ width: '40%' }}
                                                value={selectedBooking.name ? selectedBooking.name : ''}
                                                variant="outlined"
                                                onChange={(data) => {
                                                    selectedBooking.name = data.target.value
                                                    setName(data.target.value)
                                                }}
                                                style={{ width: '100%' }}
                                                error={nameError}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <GridContainer align="left" style={{ marginTop: 0 }}>
                                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                                    <TextField
                                                        label="Male (પુરુષ)"
                                                        style={{ width: '40%' }}
                                                        value={selectedBooking.male ? selectedBooking.male : 0}
                                                        variant="outlined"
                                                        onChange={(data) => {
                                                            selectedBooking.male = data.target.value;
                                                            setMale(data.target.value)
                                                        }}
                                                        style={{ width: '100%' }}
                                                        type={'number'}
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                        error={maleError}
                                                    />
                                                </GridItem>
                                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                                    <TextField
                                                        type={'number'}
                                                        label="Female (સ્ત્રી)"
                                                        style={{ width: '40%' }}
                                                        value={selectedBooking.female ? selectedBooking.female : 0}
                                                        variant="outlined"
                                                        onChange={(data) => {
                                                            // setFemale(data.target.value)
                                                            selectedBooking.female = data.target.value;
                                                        }}
                                                        style={{ width: '100%' }}
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                        error={femaleError}
                                                    />
                                                </GridItem>
                                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                                    <TextField
                                                        type={'number'}
                                                        label="Children (બાળકો)"
                                                        style={{ width: '40%' }}
                                                        value={selectedBooking.children ? selectedBooking.children : 0}
                                                        variant="outlined"
                                                        onChange={(data) => {
                                                            setChildren(data.target.value)
                                                            selectedBooking.children = data.target.value;
                                                        }}
                                                        style={{ width: '100%' }}
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                        error={childrenError}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer align="left" style={{ paddingTop: 20 }}>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <TextField
                                                id="standard-basic"
                                                label="Mobile Number (મોબાઇલ નંબર)"
                                                style={{ width: '40%' }}
                                                value={selectedBooking.bookedByPhoneNumber ? selectedBooking.bookedByPhoneNumber : ''}
                                                variant="outlined"
                                                onChange={(data) => {
                                                    // debbuger
                                                    selectedBooking.bookedByPhoneNumber = data.target.value;
                                                    setPhoneNumber(data.target.value)
                                                }}
                                                style={{ width: '100%' }}
                                                type={'number'}
                                                disabled
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <TextField
                                                id="standard-basic"
                                                label="City (શહેર)"
                                                style={{ width: '40%' }}
                                                value={selectedBooking.city ? selectedBooking.city : ''}
                                                variant="outlined"
                                                onChange={(data) => {
                                                    setCity(data.target.value)
                                                    selectedBooking.city = data.target.value;
                                                }}
                                                style={{ width: '100%' }}
                                                error={cityError}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            </GridItem>
                        </GridContainer>

                        <GridContainer align="left">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={0} style={{ padding: 20 }}>
                                    <h5>Darshan (દર્શન)</h5>
                                    <Divider />

                                    <GridContainer align="left">
                                        <GridItem xs={12} sm={6} md={6} lg={6} style={{ marginTop: '20px' }}>
                                            <DatePicker
                                                customInput={<ExampleCustomInput />}
                                                selected={date}
                                                onChange={handleDateChange}
                                                highlightDates={highlightDate}
                                                dateFormat="dd MMM yyyy"
                                                maxDate={moment(new Date()).add(6, 'months').toDate()}
                                                style={{ width: '100%' }}
                                            >
                                                <div>
                                                    <i class="fa fa-circle" style={{ color: 'green', marginLeft: 10 }}></i><span style={{ color: "green", padding: 5, fontSize: 12 }}>Slot Available</span>
                                                    <i class="fa fa-circle" style={{ color: 'red' }}></i><span style={{ color: "red", padding: 5, fontSize: 12 }}>Slot Unavailable</span>
                                                </div>
                                                <div>
                                                    <i class="fa fa-circle" style={{ color: 'grey', marginLeft: 10 }}></i><span style={{ color: "grey", padding: 5, fontSize: 12 }}>Not Applicable</span>
                                                    <i class="fa fa-circle" style={{ color: 'blue' }}></i><span style={{ color: "blue", padding: 5, fontSize: 12 }}>Current Selected</span>
                                                </div>
                                            </DatePicker>
                                            <br />
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%', marginTop: 20 }}
                                            >
                                                <InputLabel>Slot (સમય)</InputLabel>
                                                <Select
                                                    // value={selectedBooking.slot ? selectedBooking.slot.id : undefined}
                                                    value={selectedSlot ? selectedSlot : (selectedBooking.slot ? selectedBooking.slot.id : null)}
                                                    onChange={e => {
                                                        selectedBooking.slot = e.target.value;
                                                        debugger
                                                        setSelectedSlot(e.target.value)
                                                    }}
                                                    disabled={availableSlots.length == 0 ? true : false}
                                                >
                                                    {availableSlots.map((item, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={item.id}
                                                            >
                                                                {moment(new Date(parseFloat(item.fromTime))).format('hh:mm A') + " - " + moment(new Date(parseFloat(item.toTime))).format('hh:mm A')}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%', marginTop: '20px' }}
                                            >
                                                <InputLabel id="demo-simple-select-label">Photo ID Type (આધાર કાર્ડ / પાન કાર્ડ / ડ્રાઇવિંગ લાયસન્સ)</InputLabel>
                                                <Select
                                                    value={selectedBooking.idProofType ? selectedBooking.idProofType : ''}
                                                    onChange={e => {
                                                        selectedBooking.idProofType = e.target.value;
                                                        setIdProofType(e.target.value)
                                                    }}
                                                    disabled={availableSlots.length == 0 ? true : false}
                                                >
                                                    <MenuItem value={'ELECTIONCARD'}>ELECTIONCARD</MenuItem>
                                                    <MenuItem value={'AADHARCARD'}>AADHARCARD</MenuItem>
                                                    <MenuItem value={'PANCARD'}>PANCARD</MenuItem>
                                                    <MenuItem value={'DRIVINGLICENSE'}>DRIVINGLICENSE</MenuItem>
                                                    <MenuItem value={'PASSPORT'}>PASSPORT</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                id="standard-basic"
                                                label="Photo ID Number (ફોટોના પુરાવાનો કાર્ડ નંબર)"
                                                style={{ width: '40%' }}
                                                value={selectedBooking.idCardNumber ? selectedBooking.idCardNumber : ''}
                                                variant="outlined"
                                                onChange={(data) => {
                                                    setIdCardNumber(data.target.value)
                                                    selectedBooking.idCardNumber = data.target.value;
                                                }}
                                                style={{ width: '100%', marginTop: 20 }}
                                                error={idCardNumberError}
                                            />
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%', marginTop: 20 }}
                                            >
                                                <InputLabel id="demo-simple-select-label">Booking Mode (બુકિંગ પ્રકાર)</InputLabel>
                                                <Select
                                                    value={bookingMode}
                                                    onChange={e => setBookingModeType(e.target.value)}
                                                    id="standard-basic"
                                                    label="Booking Mode (બુકિંગ પ્રકાર)"
                                                    style={{ width: '100%' }}
                                                    variant="outlined"
                                                >
                                                    <MenuItem value={'TELEPHONIC'}>TELEPHONE</MenuItem>
                                                    <MenuItem value={'ON_THE_SPOT'}>ON THE SPOT</MenuItem>

                                                </Select>
                                            </FormControl>
                                            <br />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6} lg={6} style={{ marginTop: 20 }}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                    <TableHead style={{ backgroundColor: '#F3F3F3', }}>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Time Slot (સમય)</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }} align="right">Available (ઉપલબ્ધ)</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {availableSlots.length > 0 ?
                                                            availableSlots.map((item, index) => {
                                                                return (
                                                                    <TableRow style={{ backgroundColor: index % 2 == 0 && '#FEF9F2' }} key={index}>
                                                                        <TableCell component="th" scope="row">
                                                                            {moment(new Date(parseFloat(item.fromTime))).format('hh:mm A') + " - " + moment(new Date(parseFloat(item.toTime))).format('hh:mm A')}
                                                                        </TableCell>
                                                                        <TableCell align="right">{item.limit - item.totalSeatsBooked} Person ({item.limit - item.totalSeatsBooked} વ્યક્તિ)</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                            :
                                                            <TableRow style={{ backgroundColor: '#FEF9F2' }}>
                                                                <TableCell component="th" scope="row" colSpan={2} align="center">
                                                                    No Slots available
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenEditDialog(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            editBooking()
                            setOpenEditDialog(false)
                        }
                        } color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>

                <Modal
                    isOpen={isDownloading}
                    style={customStyles}
                >
                    <div>
                        <img src={require('../../assets/img/827.gif')}></img>
                    </div>
                </Modal>
            </React.Fragment>
        </>
    )
}

