import React, { useState } from 'react'
import './custom.css';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Paper from '@material-ui/core/Paper';
import { notify } from 'react-notify-toast';
import { authenticationService } from '../../services/authentication'
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export default function CreateBooking() {
    const [isLoad, setIsLoad] = React.useState(false)
    const [slot, setSlot] = React.useState([])
    const [data, setData] = React.useState("")
    const [date, setDate] = React.useState(new Date())
    const [primaryStart, setPrimaryStart] = useState(moment().toDate());
    const [primaryEnd, setPrimaryEnd] = useState(moment(new Date()).add(6, 'months').toDate());
    const [selectedSlot, setSelectedSlot] = React.useState()
    const [male, setMale] = React.useState(0)
    const [female, setFemale] = React.useState(0)
    const [children, setChildren] = React.useState(0)
    const [phoneNumber, setPhoneNumber] = React.useState()
    const [name, setName] = React.useState('')
    const [idProofType, setIdProofType] = React.useState('AADHARCARD')
    const [bookingMode, setBookingModeType] = React.useState('ON_THE_SPOT')

    const [idCardNumber, setIdCardNumber] = React.useState("")
    const [city, setCity] = React.useState("")

    const [maleError, setMaleError] = React.useState(false)
    const [femaleError, setFemaleError] = React.useState(false)
    const [childrenError, setChildrenError] = React.useState(false)
    const [phoneNumberError, setPhoneNumberError] = React.useState(false)
    const [nameError, setNameError] = React.useState(false)
    const [idProofTypeError, setIdProofTypeError] = React.useState(false)
    const [idCardNumberError, setIdCardNumberError] = React.useState(false)
    const [cityError, setCityError] = React.useState(false)

    const [highlightDate, setHighlightDate] = React.useState([
        {
            "unavailable_date_style": []
        },
        {
            "not_applicable_date_style": []
        },
        {
            "available_date_style": []
        }
    ])

    React.useEffect(() => {
        setIsLoad(true)
        let startDate = moment().toDate();
        let endDate = moment(new Date()).add(6, 'months').toDate();
        setPrimaryStart(primaryStart)
        setPrimaryEnd(primaryEnd)
        return authenticationService.fetchDateStatus(startDate, endDate).then(
            async (result) => {
                let obj = result.response

                let not_applicable_date_arr = highlightDate.find(o => o.not_applicable_date_style)
                let available_date_arr = highlightDate.find(o => o.available_date_style)
                let unavailable_date_arr = highlightDate.find(o => o.unavailable_date_style)

                // grey
                let naDates = obj.UNAVAILABLE;
                naDates.map(item => {
                    let date = new Date(parseFloat(item))
                    console.log(date)
                    not_applicable_date_arr.not_applicable_date_style.push(date)
                })

                // green
                let remainingDates = obj.REMAINING;
                remainingDates.map(item => {
                    let date = new Date(parseFloat(item))
                    console.log(date)
                    available_date_arr.available_date_style.push(date)
                })

                // red
                let unavailableDates = obj.FULL;
                unavailableDates.map(item => {
                    let date = new Date(parseFloat(item))
                    console.log(date)
                    unavailable_date_arr.unavailable_date_style.push(date)
                })

                setHighlightDate([not_applicable_date_arr, available_date_arr, unavailable_date_arr])
                await handleDateChange(startDate)
                setIsLoad(false)
            }).catch((error) => {
                console.log(error)
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }, [])

    const handleDateChange = (date) => {
        setIsLoad(true)
        setDate(date)
        return authenticationService.fetchSlotByDate(date, date).then(
            (result) => {
                console.log('result.slots', result.slots)
                if (result.slots != {}) {
                    let key = Object.keys(result.slots)[0];
                    setSlot(result.slots[key])
                } else {
                    setSlot([])
                }
                setIsLoad(false)

            }).catch((error) => {
                console.log(error)
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
            })
    };

    const ExampleCustomInput = ({ value, onClick }) => (
        <TextField
            id="standard-basic"
            label="Date (તારીખ)"
            style={{ width: '40%' }}
            value={value}
            variant="outlined"
            onClick={onClick}
            style={{ width: '100%' }}
        />
    );

    const doCreateBooking = () => {
        let isFormError = false
        if (phoneNumber == "") {
            isFormError = true
            setPhoneNumberError(true)
        }
        if (male.length == 0) {
            isFormError = true
            setMaleError(true)
        }
        if (name == "") {
            isFormError = true
            setNameError(true)
        }
        if (female.length == 0) {
            isFormError = true
            setFemaleError(true)
        }
        if (children.length == 0) {
            isFormError = true
            setChildrenError(true)
        }
        if (city == "") {
            isFormError = true
            setCityError(true)
        }
        if (idCardNumber == "") {
            isFormError = true
            setIdCardNumberError(true)
        }
        if (isFormError) {
            return
        }

        setIsLoad(true)
        return authenticationService.createBooking(
            phoneNumber,
            name,
            idProofType,
            idCardNumber,
            parseFloat(children),
            parseFloat(male),
            parseFloat(female),
            parseFloat(selectedSlot),
            date,
            city,
            bookingMode
        ).then(
            async (result) => {
                debugger
                notify.show("Booking created", "success")
                setTimeout(() => {
                    window.location.reload()
                }, 500)
                setIsLoad(false)
            }).catch((error) => {
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }

    return (
        <>
            <React.Fragment>

                {/* LOADER */}
                <GridContainer align="center" style={{ paddingTop: isLoad ? 40 : 0 }}>
                    <GridItem xs={12} sm={4} md={4} lg={4}></GridItem>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                        {isLoad && <CircularProgress />}
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} lg={4} align="right"></GridItem>
                </GridContainer>

                {!isLoad && <GridContainer align="center">
                    <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
                    <GridItem xs={0} sm={0} md={8} lg={8}>

                        <GridContainer align="left" style={{ paddingTop: 20 }}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={1} style={{ padding: 20 }}>
                                    <h5>Personal Details (અંગત વિગતો)</h5>
                                    <Divider />
                                    <GridContainer align="left" style={{ paddingTop: 20 }}>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <TextField
                                                id="standard-basic"
                                                label="Name (નામ)"
                                                style={{ width: '40%' }}
                                                value={name}
                                                variant="outlined"
                                                onChange={(data) => setName(data.target.value)}
                                                style={{ width: '100%' }}
                                                error={nameError}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <GridContainer align="left" style={{ marginTop: 0 }}>
                                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                                    <TextField
                                                        label="Male (પુરુષ)"
                                                        style={{ width: '40%' }}
                                                        value={male}
                                                        variant="outlined"
                                                        onChange={(data) => setMale(data.target.value)}
                                                        style={{ width: '100%' }}
                                                        type={'number'}
                                                        error={maleError}
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                                    <TextField
                                                        type={'number'}
                                                        label="Female (સ્ત્રી)"
                                                        style={{ width: '40%' }}
                                                        value={female}
                                                        variant="outlined"
                                                        onChange={(data) => setFemale(data.target.value)}
                                                        style={{ width: '100%' }}
                                                        error={femaleError}
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                                    <TextField
                                                        type={'number'}
                                                        label="Children (બાળકો)"
                                                        style={{ width: '40%' }}
                                                        value={children}
                                                        variant="outlined"
                                                        onChange={(data) => setChildren(data.target.value)}
                                                        style={{ width: '100%' }}
                                                        error={childrenError}
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer align="left" style={{ paddingTop: 20 }}>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <TextField
                                                id="standard-basic"
                                                label="Mobile Number (મોબાઇલ નંબર)"
                                                style={{ width: '40%' }}
                                                value={phoneNumber}
                                                variant="outlined"
                                                onChange={(data) => setPhoneNumber(data.target.value)}
                                                style={{ width: '100%' }}
                                                type={'number'}
                                                error={phoneNumberError}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <TextField
                                                id="standard-basic"
                                                label="City (શહેર)"
                                                style={{ width: '40%' }}
                                                value={city}
                                                variant="outlined"
                                                onChange={(data) => setCity(data.target.value)}
                                                style={{ width: '100%' }}
                                                error={cityError}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            </GridItem>
                        </GridContainer>

                        <GridContainer align="left" style={{ paddingTop: 20 }}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={1} style={{ padding: 20 }}>
                                    <h5>Darshan (દર્શન)</h5>
                                    <Divider />

                                    <GridContainer align="left" style={{ paddingTop: 20 }}>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <DatePicker
                                                customInput={<ExampleCustomInput />}
                                                selected={date}
                                                onChange={handleDateChange}
                                                highlightDates={highlightDate}
                                                dateFormat="dd MMM yyyy"
                                                maxDate={moment(new Date()).add(6, 'months').toDate()}
                                                style={{ width: '100%' }}
                                            >
                                                <div>
                                                    <i class="fa fa-circle" style={{ color: 'green', marginLeft: 10 }}></i><span style={{ color: "green", padding: 5, fontSize: 12 }}>Slot Available</span>
                                                    <i class="fa fa-circle" style={{ color: 'red' }}></i><span style={{ color: "red", padding: 5, fontSize: 12 }}>Slot Unavailable</span>
                                                </div>
                                                <div>
                                                    <i class="fa fa-circle" style={{ color: 'grey', marginLeft: 10 }}></i><span style={{ color: "grey", padding: 5, fontSize: 12 }}>Not Applicable</span>
                                                    <i class="fa fa-circle" style={{ color: 'blue' }}></i><span style={{ color: "blue", padding: 5, fontSize: 12 }}>Current Selected</span>
                                                </div>
                                            </DatePicker>
                                            <br />
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%', marginTop: 20 }}
                                            >
                                                <InputLabel>Slot (સમય)</InputLabel>
                                                <Select
                                                    value={selectedSlot}
                                                    onChange={e => setSelectedSlot(e.target.value)}
                                                    disabled={slot.length == 0 ? true : false}
                                                    id="standard-basic"
                                                    label="City (સમય)"
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                >
                                                    {slot.map((item, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={item.id}
                                                            >
                                                                {moment(new Date(parseFloat(item.fromTime))).format('hh:mm A') + " - " + moment(new Date(parseFloat(item.toTime))).format('hh:mm A')}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%', marginTop: 20 }}
                                            >
                                                <InputLabel id="demo-simple-select-label">Photo ID Type (આધાર કાર્ડ / પાન કાર્ડ / ડ્રાઇવિંગ લાયસન્સ)</InputLabel>
                                                <Select
                                                    value={idProofType}
                                                    onChange={e => setIdProofType(e.target.value)}
                                                    disabled={slot.length == 0 ? true : false}
                                                    id="standard-basic"
                                                    label="Photo ID Type (આધાર કાર્ડ / પાન કાર્ડ / ડ્રાઇવિંગ લાયસન્સ)"
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                >
                                                    <MenuItem value={'AADHARCARD'}>AADHARCARD</MenuItem>
                                                    <MenuItem value={'ELECTIONCARD'}>ELECTIONCARD</MenuItem>
                                                    <MenuItem value={'PANCARD'}>PANCARD</MenuItem>
                                                    <MenuItem value={'DRIVINGLICENSE'}>DRIVINGLICENSE</MenuItem>
                                                    <MenuItem value={'PASSPORT'}>PASSPORT</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                id="standard-basic"
                                                label="Photo ID Number (ફોટોના પુરાવાનો કાર્ડ નંબર)"
                                                style={{ width: '40%' }}
                                                value={idCardNumber}
                                                variant="outlined"
                                                onChange={(data) => setIdCardNumber(data.target.value)}
                                                style={{ width: '100%', marginTop: 20 }}
                                                error={idCardNumberError}
                                            />
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%', marginTop: 20 }}
                                            >
                                                <InputLabel id="demo-simple-select-label">Booking Mode (બુકિંગ પ્રકાર)</InputLabel>
                                                <Select
                                                    value={bookingMode}
                                                    onChange={e => setBookingModeType(e.target.value)}
                                                    disabled={slot.length == 0 ? true : false}
                                                    id="standard=basic"
                                                    label="Booking Mode (બુકિંગ પ્રકાર)"
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                >
                                                    <MenuItem value={'TELEPHONIC'}>TELEPHONIC</MenuItem>
                                                    <MenuItem value={'ON_THE_SPOT'}>ON THE SPOT</MenuItem>

                                                </Select>
                                            </FormControl>
                                            <br />
                                        </GridItem>

                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                    <TableHead style={{ backgroundColor: '#F3F3F3', }}>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Time Slot (સમય)</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }} align="right">Available (ઉપલબ્ધ)</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {slot.length > 0 ?
                                                            slot.map((item, index) => {
                                                                return (
                                                                    <TableRow style={{ backgroundColor: index % 2 == 0 && '#FEF9F2' }} key={index}>
                                                                        <TableCell component="th" scope="row">
                                                                            {moment(new Date(parseFloat(item.fromTime))).format('hh:mm A') + " - " + moment(new Date(parseFloat(item.toTime))).format('hh:mm A')}
                                                                        </TableCell>
                                                                        <TableCell align="right">{item.limit - item.totalSeatsBooked} Person ({item.limit - item.totalSeatsBooked} વ્યક્તિ)</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                            :
                                                            <TableRow style={{ backgroundColor: '#FEF9F2' }}>
                                                                <TableCell component="th" scope="row" colSpan={2} align="center">
                                                                    No Slots available
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer align="left" style={{ paddingTop: 20 }}>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={slot.length == 0 ? true : false}
                                                onClick={doCreateBooking}
                                            >
                                                Create Booking
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            </GridItem>
                        </GridContainer>

                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
                </GridContainer>}
            </React.Fragment>
        </>
    )
}

