import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import { notify } from 'react-notify-toast';
import CircularProgress from '@material-ui/core/CircularProgress';
import Person from '@material-ui/icons/Person'

// service
import { authenticationService } from '../../services/authentication'

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function Login(props) {
  const [load, setLoad] = React.useState(false)
  const [emailErr, setEmailErr] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [passwordErr, setPasswordErr] = React.useState(false)
  const classes = useStyles();
  const onChangeEmail = event => {
    let email = event.target.value
    if (email == "") {
      setEmail(email)
      setEmailErr(true)
      return
    }
    setEmailErr(false)
    setEmail(email)
  }
  const onChangePassword = event => {
    let password = event.target.value
    if (password == "") {
      setPassword(password)
      setPasswordErr(true)
      return
    }
    setPasswordErr(false)
    setPassword(password)
  }
  const onSubmit = () => {
    let err = false
    if (email == "") {
      setEmailErr(true)
      err = true
    }
    if (password == "") {
      setPasswordErr(true)
      err = true
    }
    if (err) {
      return
    }
    setLoad(true)
    return authenticationService.login(email, password).then(
      (result) => {
        setLoad(false)
        console.log(result)
        notify.show('You are successfully logged in.', 'success');
        setTimeout(() => {
          window.location.replace('/panel/bookings');
        }, 500)
      }).catch((error) => {
        console.log(error)
        setLoad(false)
        if (error == "TypeError: Failed to fetch") {
          error = 'Internal server error'
        }
        notify.show(error, 'custom', 5000, {
          background: '#F55A4E', text: "#FFFFFF"
        });
        console.log('error', error)
      })
  }
  return (
    <div align="center">
      <GridContainer style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <GridItem xs={11} sm={11} md={4} lg={4} style={{ marginTop: 100 }}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Darshan Booking System</h4>
              <p className={classes.cardCategoryWhite}>Login</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange={onChangeEmail}
                    error={emailErr}
                    type={'text'}
                    autocomplete="username email"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange={onChangePassword}
                    error={passwordErr}
                    type={'password'}
                    autocomplete="new-password"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter align="center">
              {load ?
                <CircularProgress style={{ marginLeft: 50 }} />
                :
                <Button color="warning" onClick={onSubmit}>Login</Button>
              }
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
