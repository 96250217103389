import { currentUserSubject, api_endpoint } from "../consonants";
import { authHeader, xAuthToken } from "./authHeader";
import { handleResponse } from "./handleResponse";

export const authenticationService = {
  login,
  logout,
  addSlot,
  updateSlot,
  deleteSlot,
  carryForwardSlot,
  fetchDateStatus,
  fetchSlotByDate,
  fetchBooking,
  fetchBookingDetails,
  cancleBooking,
  deleteBookingsOfSlot,
  createBooking,
  editBooking,
  downloadPDF,
  currentUser: currentUserSubject.asObservable(),
  get currentUser() {
    return currentUserSubject.value;
  }
};

function storeUser(user) {
  localStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
}

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password })
  };

  return fetch(api_endpoint + `/admin/login`, requestOptions)
    // return fetch(`https://api.clockify.me/api/auth/token`, requestOptions)
    .then(handleResponse)
    .then(user => {
      storeUser(user);
      return user;
    });
}

function fetchDateStatus(fromDate, toDate) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ fromDate, toDate })
  };

  return fetch(api_endpoint + `/date/status`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function fetchSlotByDate(fromDate, toDate) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ fromDate, toDate })
  };

  return fetch(api_endpoint + `/date/slots`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function addSlot(date, slot) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ date, slot })
  };

  return fetch(api_endpoint + `/admin/add-slot`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateSlot(slotId, slot) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ slotId, slot })
  };
  console.log(requestOptions)
  return fetch(api_endpoint + `/admin/slot`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteSlot(slotId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(api_endpoint + `/admin/slot/${slotId}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function carryForwardSlot(date, fromDate, toDate) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ date, fromDate, toDate })
  };

  return fetch(api_endpoint + `/admin/slot/carry-forward`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function fetchBooking(slotId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(api_endpoint + `/admin/booking/${slotId}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function fetchBookingDetails(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ data })
  };

  return fetch(api_endpoint + `/admin/booking/get-booking`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function cancleBooking(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(api_endpoint + `/admin/booking/${id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteBookingsOfSlot(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(api_endpoint + `/admin/slot/${id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createBooking(
  bookedByPhoneNumber,
  name,
  idProofType,
  idCardNumber,
  children,
  male,
  female,
  slot,
  date,
  city,
  bookingMode
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      bookedByPhoneNumber,
      name,
      idProofType,
      idCardNumber,
      children,
      male,
      female,
      slot,
      date,
      city,
      bookingMode
    })
  };

  return fetch(api_endpoint + `/admin/booking/create-booking`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function editBooking(
  id,
  bookedByPhoneNumber,
  name,
  idProofType,
  idCardNumber,
  children,
  male,
  female,
  slot,
  date,
  city,
  bookingMode
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      id,
      bookedByPhoneNumber,
      name,
      idProofType,
      idCardNumber,
      children,
      male,
      female,
      slot,
      date,
      city,
      bookingMode
    })
  };
  console.log(requestOptions);
  return fetch(api_endpoint + `/admin/booking`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function downloadPDF(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ data })
  };
  debugger

  return fetch(api_endpoint + `/booking/ticket`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function logout() {
  // remove user from local storage to log user out
  window.localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}

