import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import { authenticationService } from '../../services/authentication'
import { notify } from 'react-notify-toast';
import TextField from '@material-ui/core/TextField';
import { currentUserSubject } from '../../consonants'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "components/CustomButtons/Button.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import CardFooter from "components/Card/CardFooter.js";
import Person from '@material-ui/icons/Person'
import CardAvatar from "components/Card/CardAvatar.js";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
}));
export default function Profile() {
    const classes = useStyles();
    const [load, setLoad] = React.useState(false)
    const [fetchLoad, setFetchLoad] = React.useState(false)
    const [user, setUser] = React.useState(null)
    const [clockifyUser, setClockifyUser] = React.useState(null)
    const [isReportingManager, setIsReportingManager] = React.useState('USER')
    React.useEffect(() => {
        setFetchLoad(true)
        return authenticationService.me().then(
            (result) => {
                setFetchLoad(false)
                setUser(result.user)
                result.user.isReportingManager ? setIsReportingManager('REPORTING_MANAGER') : setIsReportingManager('USER')
                return authenticationService.clocifyProfile()
                    .then(data => {
                        setClockifyUser(data)
                    })
                    .catch(error => {
                        setFetchLoad(false)
                        if (error == "TypeError: Failed to fetch") {
                            error = 'Internal server error'
                        }
                        notify.show(error, 'custom', 5000, {
                            background: '#F55A4E', text: "#FFFFFF"
                        });
                        console.log('error', error)
                    })
            }).catch((error) => {
                setFetchLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }, [])
    const onSubmit = () => {
        setLoad(true)
        let isReportingManagerFlag = (isReportingManager === 'USER') ? false : true
        return authenticationService.changeStatus(isReportingManagerFlag).then(
            (result) => {
                notify.show("profile updated successfully", "success")
                setLoad(false)
                setUser(result.user)
                result.user.isReportingManager ? setIsReportingManager('REPORTING_MANAGER') : setIsReportingManager('USER')
            }).catch((error) => {
                setLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }
    const handleChangeRadio = (event) => {
        setIsReportingManager(event.target.value)
    }
    return (
        <React.Fragment>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card profile style={{ marginTop: 0 }}>
                        {/* <CardHeader color="warning"
                            icon
                        >
                            <CardIcon color="warning">
                                <Person />
                            </CardIcon>
                        </CardHeader> */}
                        {fetchLoad ?
                            <CircularProgress style={{ marginLeft: '50%', marginTop: '8%', marginBottom: '8%' }} />
                            :
                            <React.Fragment>
                                <CardBody align="left">
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h5 style={{ fontWeight: 'bold' }}>Profile photo</h5>
                                            <div>
                                                <img
                                                    src={clockifyUser ? clockifyUser.profilePicture : require('assets/img/faces/default.png')}
                                                    alt="..."
                                                    style={{
                                                        height: 100,
                                                        width: 100,
                                                        objectFit: 'cover',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <Divider />
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h5 style={{ fontWeight: 'bold' }}>Personal info</h5>
                                            <TextField
                                                disabled
                                                style={{ width: '50%' }}
                                                id="outlined-basic"
                                                label="Name"
                                                value={clockifyUser ? clockifyUser.name : currentUserSubject.value.name}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                                            <TextField
                                                disabled
                                                style={{ width: '50%' }}
                                                id="outlined-basic"
                                                label="Email"
                                                value={currentUserSubject.value.email}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                                            <FormControlLabel
                                                value="start"
                                                control={
                                                    <Radio
                                                        checked={isReportingManager === 'REPORTING_MANAGER'}
                                                        value="REPORTING_MANAGER"
                                                        color="primary"
                                                        onChange={handleChangeRadio}
                                                    />}
                                                label="I am a reporting manager"
                                                labelPlacement="end"
                                                style={{ fontFamily: 'Noto_Sansregular' }}
                                                className={'customFont'}
                                            />
                                            <FormControlLabel
                                                value="start"
                                                control={
                                                    <Radio
                                                        checked={isReportingManager === 'USER'}
                                                        value="USER"
                                                        color="primary"
                                                        onChange={handleChangeRadio}
                                                    />}
                                                label="I am not a reporting manager"
                                                labelPlacement="end"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                                            <a href="https://clockify.me/user/settings" target="_blank" style={{ textDecoration: 'underline' }}>Click here</a> to update your profile from clockify
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter align="center">
                                    {load ?
                                        <CircularProgress style={{ marginLeft: 50 }} />
                                        :
                                        <Button color="warning" onClick={onSubmit}>Update profile</Button>
                                    }
                                </CardFooter>
                            </React.Fragment>
                        }
                    </Card>
                </GridItem>


            </GridContainer>
        </React.Fragment>
    );
}