import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MaterialTable from "material-table";
import { authenticationService } from '../../services/authentication'
import { notify } from 'react-notify-toast';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
// import CardIcon from "components/Card/CardIcon.js";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {
    typo: {
        // paddingLeft: "25%",
        marginBottom: "40px",
        position: "relative"
    },
    note: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        bottom: "10px",
        color: "#c0c1c2",
        display: "block",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "13px",
        left: "0",
        marginLeft: "20px",
        position: "absolute",
        width: "260px"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function SearchBooking(props) {
    const [isLoad, setIsLoad] = React.useState(false)
    const [isDataLoaded, setIsDataLoaded] = React.useState(false)
    const [data, setData] = React.useState("")
    const [futureBookings, setFutureBookings] = React.useState([])
    const [pastBookings, setPastBookings] = React.useState([])
    const [bookings, setBookings] = React.useState([])
    const [activeTab, setActiveTab] = React.useState(0);
    const [isOpen, setIsOpen] = React.useState(false);
    const [deleteBookingId, setDeleteBookingId] = React.useState();

    const handleTabChange = async (event, newValue) => {
        setActiveTab(newValue);
        if (newValue == 0) {
            setBookings(futureBookings)
        } else if (newValue == 1) {
            setBookings(pastBookings)
        }
    };

    const handleChangeData = (event) => {
        setData(event.target.value)
    }

    const fetchBookingDetails = () => {
        setIsLoad(true)
        return authenticationService.fetchBookingDetails(data).then(
            (result) => {
                console.log(result.result)
                let futureBookings = result.result.filter(item => new Date(parseFloat(item.slot.fromTime)) >= new Date())
                setFutureBookings(futureBookings)
                let pastBookings = result.result.filter(item => new Date(parseFloat(item.slot.fromTime)) < new Date())
                setPastBookings(pastBookings)
                setBookings(futureBookings)
                setIsDataLoaded(true)
                notify.show("Booking retrieved", "success")
                setIsLoad(false)
            }).catch((error) => {
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
            })
    }

    const deleteBooking = (id) => {
        setIsLoad(true)
        return authenticationService.cancleBooking(id).then(
            (result) => {
                debugger
                notify.show("Booking cancelled", "success")
                let updatedFutureBookings = futureBookings.filter(item => item.id != id)
                setFutureBookings(updatedFutureBookings)
                setIsOpen(false)
                setIsLoad(false)
            }).catch((error) => {
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
            })
    }

    const deleteSlot = (id) => {
        setIsLoad(true)
        return authenticationService.deleteSlot(id).then(
            (result) => {
                debugger
                notify.show("Booking cancelled", "success")
                let updatedFutureBookings = futureBookings.filter(item => item.id != id)
                setFutureBookings(updatedFutureBookings)
                setIsOpen(false)
                setIsLoad(false)
            }).catch((error) => {
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
            })
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            fetchBookingDetails()
        }
    }

    const downloadPDFAPI = (bookingId) => {

        return authenticationService.downloadPDF(
            bookingId
        ).then(
            async (result) => {
                debugger
                if (result.ticket) {
                    const pdf = `data:application/pdf;base64,${result.ticket}`;
                    debugger
                    const linkSource = `${pdf}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "Gatepass.pdf";

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }

            }).catch((error) => {
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }



    return (
        <React.Fragment>
            <GridContainer align="center">
                <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
                <GridItem xs={0} sm={0} md={8} lg={8}>

                    {/* SEARCH BOOKING */}
                    <GridContainer align="center" style={{ marginBottom: '20px !important' }}>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Paper square>
                                <GridContainer align="center" style={{ marginBottom: '20px !important' }}>
                                    <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 15, marginTop: 15 }}>
                                        <TextField
                                            id="standard-basic"
                                            label="Enter mobile number or booking id"
                                            style={{ width: '40%' }}
                                            value={data}
                                            variant="outlined"
                                            onChange={handleChangeData}
                                            onKeyDown={keyPress}
                                        // type={'number'}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 15 }}>
                                        <Button
                                            onClick={() => { }}
                                            variant="contained"
                                            color="primary"
                                            style={{ marginBottom: 8 }}
                                            disabled={data != "" ? false : true}
                                            onClick={fetchBookingDetails}
                                        >
                                            Search Bookings
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </Paper>
                        </GridItem>
                    </GridContainer>
                    <br />
                    {/* TAB */}
                    {isDataLoaded && <GridContainer align="center" style={{ marginTop: 20 }}>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Paper square>
                                <Tabs
                                    value={activeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTabChange}
                                    centered
                                >
                                    <Tab label="Future Bookings" />
                                    <Tab label="Past Bookings" />
                                </Tabs>
                            </Paper>
                        </GridItem>
                    </GridContainer>}

                    {/* LOADER */}
                    <GridContainer align="center" style={{ paddingTop: isLoad ? 40 : 0 }}>
                        <GridItem xs={12} sm={4} md={4} lg={4}></GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                            {isLoad && <CircularProgress />}
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4} align="right"></GridItem>
                    </GridContainer>

                    <GridContainer align="left" style={{ paddingTop: 20 }}>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            {isDataLoaded && activeTab == 0 && (futureBookings.length > 0 ? futureBookings.map((item, index) => {
                                return (
                                    <Paper elevation={1} key={index}>
                                        <div className="booking-wrap">
                                            <div className="row" style={{ backgroundColor: '#e0e0e0', margin: 0, height: 60 }}>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <p style={{ marginTop: 15, fontWeight: 'bold' }}><span>Booking ID:</span> <span>{item.bookingId}</span>
                                                        <span
                                                            onClick={() => {
                                                                downloadPDFAPI(item.bookingId)
                                                            }}
                                                        ><img src={require('../../assets/img/print.png')} style={{ marginLeft: 15, height: 30, width: 30 }} /></span>
                                                    </p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" align="right">
                                                    <div onClick={() => {
                                                        setIsOpen(true)
                                                        setDeleteBookingId(item.id)
                                                    }} style={{ cursor: 'pointer' }}>
                                                        <p style={{ marginTop: 15, fontWeight: 'bold', color: 'red' }}>
                                                            <img src={require('../../assets/img/delete_icon.png')} />
                                                            <span style={{ paddingLeft: 5, paddingTop: 5 }}>Cancel Booking</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="booking-detail">
                                                <div className="row">
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Name:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{item.name}</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Mobile No:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{item.bookedByPhoneNumber}</span></p>
                                                    </div>
                                                    {/* <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Date of Birth:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{moment(parseFloat(item.dateOfBirth)).format('DD MMM YYYY')}</span></p>
                                                    </div> */}
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">No. of Person:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{item.personCount}</span></p>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Booking Date:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{moment(item.slot.fromTime, "x").format('DD MMM YYYY')}</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Booking Time:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{moment(item.slot.fromTime, "x").format('hh:mm A')}</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">City:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{item.city}</span></p>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Address:</span></p>
                                                    </div>
                                                    <div className="col-lg-10">
                                                        <p><span className="user_name actual-data">{item.address}</span></p>
                                                    </div>
                                                </div> */}
                                                <div class="row">
                                                    <div className="col-6 col-lg-2">
                                                        <p><span class="user_label light-label">Photo ID Number:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span class="user_name actual-data">{item.idCardNumber}</span></p>
                                                    </div>
                                                    <div class="col-6 col-lg-2">
                                                        <p><span class="user_label light-label">Booking Mode:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span class="user_name actual-data">{item.bookingMode == "ON_THE_SPOT" ? "ON THE SPOT" : item.bookingMode}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                )
                            })
                                :
                                <Paper elevation={1}>
                                    <GridContainer align="center">
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <div style={{ height: 100, paddingTop: 30 }}>
                                                <span>No Future Bookings</span>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            )}
                            {isDataLoaded && activeTab == 1 && (pastBookings.length > 0 ? pastBookings.map((item, index) => {
                                return (
                                    <Paper elevation={1} key={index}>
                                        <div className="booking-wrap">
                                            <h3 style={{ margin: 0, backgroundColor: '#e0e0e0' }}> <span>Booking ID:</span> <span>{item.bookingId}</span></h3>
                                            <div className="booking-detail">
                                                <div className="row">
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Name:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{item.name}</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Mobile No:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{item.bookedByPhoneNumber}</span></p>
                                                    </div>
                                                    {/* <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Date of Birth:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{moment(parseFloat(item.dateOfBirth)).format('DD MMM YYYY')}</span></p>
                                                    </div> */}
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">No. of Person:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{item.personCount}</span></p>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Booking Date:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{moment(item.slot.fromTime, "x").format('DD MMM YYYY')}</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Booking Time:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{moment(item.slot.fromTime, "x").format('hh:mm A')}</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">City:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_name actual-data">{item.city}</span></p>
                                                    </div>

                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-6 col-lg-2">
                                                        <p><span className="user_label light-label">Address:</span></p>
                                                    </div>
                                                    <div className="col-lg-10">
                                                        <p><span className="user_name actual-data">{item.address}</span></p>
                                                    </div>
                                                </div> */}
                                                <div class="row">
                                                    <div className="col-6 col-lg-2">
                                                        <p><span class="user_label light-label">Photo ID Number:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span class="user_name actual-data">{item.idCardNumber}</span></p>
                                                    </div>
                                                    <div class="col-6 col-lg-2">
                                                        <p><span class="user_label light-label">Booking Mode:</span></p>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p><span class="user_name actual-data">{item.bookingMode == "ON_THE_SPOT" ? "ON THE SPOT" : item.bookingMode}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                )
                            })
                                :
                                <Paper elevation={1}>
                                    <GridContainer align="center">
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <div style={{ height: 100, paddingTop: 30 }}>
                                                <span>No Past Bookings</span>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            )}
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
            </GridContainer>

            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to cancel this booking?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => {
                        setIsOpen(false)
                        setDeleteBookingId()
                    }} color="primary">
                        Close
                    </Button>
                    <Button onClick={() => deleteBooking(deleteBookingId)} color="primary">
                        Cancel booking
                    </Button>
                </DialogActions>
            </Dialog>


        </React.Fragment >
    );
}