import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";
import { currentUserSubject } from '../../consonants'
import logo from "assets/img/reactlogo.png";

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }
  var { color } = props;
  color = 'warning'
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  var brand = (
    <div className={classes.logo}>
      <a
        href="/panel/bookings"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        style={{
          color: '#000',
          fontSize: 17,
          paddingBottom: 0,
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div className={classes.logoImage}>
          <img src={require('../../assets/img/logo.png')} alt="logo" style={{ height: 40, width: 40 }} />
        </div>
        <div style={{ paddingTop: 8, marginLeft: 5 }}>
          {'Darshan Booking System'}
        </div>
      </a>
    </div>
  );
  return (
    <AppBar className={classes.appBar} style={{
      backgroundColor: '#fff',
      boxShadow: '0 5px 10px rgba(0,0,0,.1)'
    }}>

      <Toolbar className={classes.container}>
        {!props.isDrawerOpen &&
          <Hidden smDown implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawer}
            >
              <Menu />
            </IconButton>
          </Hidden>}
        {brand}

        <div className={classes.flex} style={{ paddingLeft: 10 }}>
          {/* Here we create navbar brand, based on route name */}
          <Hidden smDown implementation="css">
            <Button color={"transparent"} href="/panel/booking/search" className={`${classes.title} customFont`} style={{ fontSize: 25, border: makeBrand() == 'Search Booking' ? '1px solid grey' : "none" }}>
              <span style={{ fontSize: 20 }}>{'Search Booking'}</span>
            </Button>
            <Button color={"transparent"} href="/panel/bookings" className={`${classes.title} customFont`} style={{ fontSize: 25, border: makeBrand() == 'View Bookings' ? '1px solid grey' : "none" }}>
              <span style={{ fontSize: 20 }}>{'View Bookings'}</span>
            </Button>
            <Button color={"transparent"} href="/panel/slot" className={`${classes.title} customFont`} style={{ fontSize: 25, border: makeBrand() == 'Manage Slot' ? '1px solid grey' : "none" }}>
              <span style={{ fontSize: 20 }}>{'Manage Slots'}</span>
            </Button>
            <Button color={"transparent"} href="/panel/booking/create" className={`${classes.title} customFont`} style={{ fontSize: 25, border: makeBrand() == 'Create Booking' ? '1px solid grey' : "none" }}>
              <span style={{ fontSize: 20 }}>{'Create Booking'}</span>
            </Button>
          </Hidden>
        </div>
        {/* <a href="https://clockify.me/user/settings" target="blank">Welcome, {currentUserSubject.value.name}</a> */}
        <Hidden smDown implementation="css">
          {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
