import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MaterialTable from "material-table";
import { authenticationService } from '../../services/authentication'
import { notify } from 'react-notify-toast';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
// import CardIcon from "components/Card/CardIcon.js";
import Typography from '@material-ui/core/Typography';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button';

const styles = {
  typo: {
    // paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [timeEntries, setTimeEntries] = React.useState([])
  const [isLoad, setIsLoad] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState(0);
  const [cStartDate, setCStartDate] = React.useState(new Date());
  const [cEndDate, setCEndDate] = React.useState(null);

  React.useEffect(async () => {
    setIsLoad(true)
    setIsLoad(false)
    await fetchBookingForTheDate(new Date(), new Date())
  }, [])

  const handleCStartDateChange = (date) => {
    setCStartDate(date)
  };

  const handleCEndDateChange = (date) => {
    setCEndDate(date)
  };

  const handleTabChange = async (event, newValue) => {
    setIsLoad(true)
    setActiveTab(newValue);
    let startDate = new Date();
    let endDate = new Date();

    if (newValue == 1) {
      // this week
      endDate = moment().add(7, 'days').calendar();
    } else if (newValue == 2) {
      // this month
      endDate = moment().add(1, 'months').calendar();
    }

    await fetchBookingForTheDate(startDate, new Date(endDate))
    setIsLoad(false)
  };

  const onClickCustomFilter = async () => {
    setIsLoad(true)
    await fetchBookingForTheDate(cStartDate, (cEndDate ? cEndDate : cStartDate))
    setIsLoad(false)
  }

  const fetchBookingForTheDate = (startDate, endDate) => {
    return authenticationService.fetchSlotByDate(startDate, endDate).then(
      (result) => {
        if (result.slots != {}) {
          setTimeEntries(result.slots)
        } else {
          setTimeEntries({})
        }
        setIsLoad(false)

      }).catch((error) => {
        setIsLoad(false)
        if (error == "TypeError: Failed to fetch") {
          error = 'Internal server error'
        }
        notify.show(error, 'custom', 5000, {
          background: '#F55A4E', text: "#FFFFFF"
        });
      })
  }

  const ExampleCustomInput = ({ value, onClick }) => (
    <Button onClick={onClick} color={"transparent"} className={`customFont`} style={{ fontSize: 15, border: '1px solid grey', height: 30 }}>
      {value}
    </Button>
  );

  return (
    <React.Fragment>
      {/* TAB */}
      <GridContainer align="center">
        <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
        <GridItem xs={0} sm={0} md={8} lg={8}>
          <GridContainer align="center" style={{ marginBottom: '20px !important' }}>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Paper square>
                <Tabs
                  value={activeTab}
                  indicatorColor="#FFFFFF"
                  textColor="#FFFFFF"
                  onChange={handleTabChange}
                  centered
                  style={{
                    outline: 'none',
                    boxShadow: 'none',
                  }}
                >
                  <Tab label="Today" />
                  <Tab label="This Week" />
                  <Tab label="This Month" />
                  <Tab label="Custom Date" />
                </Tabs>
              </Paper>
            </GridItem>
          </GridContainer>

          {/* LOADER */}
          <GridContainer align="center" style={{ paddingTop: isLoad ? 40 : 0 }}>
            <GridItem xs={12} sm={4} md={4} lg={4}></GridItem>
            <GridItem xs={12} sm={4} md={4} lg={4}>
              {isLoad && <CircularProgress />}
            </GridItem>
            <GridItem xs={12} sm={4} md={4} lg={4} align="right"></GridItem>
          </GridContainer>

          {!isLoad && activeTab == 3 &&
            <GridContainer align="left" style={{ paddingTop: 20 }}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Paper elevation={1}>
                  <GridContainer align="center" style={{ paddingTop: 15, paddingBottom: 15 }}>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                      <span style={{ marginRight: 10 }}>Start Date:</span>
                      <DatePicker
                        selected={cStartDate}
                        onChange={handleCStartDateChange}
                        style={{
                          padding: 10,
                          margin: 10,
                        }}
                        customInput={<ExampleCustomInput />}
                        dateFormat="dd MMM yyyy"
                      />

                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                      <span style={{ marginRight: 10 }}>End Date:</span>
                      <DatePicker
                        selected={cEndDate ? cEndDate : cStartDate}
                        onChange={handleCEndDateChange}
                        style={{
                          padding: 10,
                          margin: 10,
                        }}
                        minDate={cStartDate}
                        maxDate={moment(cStartDate).add(3, 'months').toDate()}
                        customInput={<ExampleCustomInput />}
                        dateFormat="dd MMM yyyy"
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                      <Button
                        onClick={onClickCustomFilter}
                        variant="contained"
                        color="#e0e0e0"
                        style={{ marginTop: 10 }}
                      >
                        Search Bookings
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Paper>
              </GridItem>
            </GridContainer>}

          {/* RESULT */}
          {!isLoad &&
            Object.keys(timeEntries).map(function (key, index) {
              if (timeEntries[key].length >= 0) {
                return (
                  <Card style={{ marginTop: 30 }}>
                    <CardBody style={{ padding: '0px 15px 15px 15px' }}>
                      <GridContainer align="left">
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <p style={{ paddingTop: 10, fontWeight: 'bold' }}>Date: {moment(new Date(parseFloat(key))).format('DD-MMM-YYYY')}</p>
                          <MaterialTable
                            style={{ marginTop: 15 }}
                            components={{
                              Container: props => <Paper {...props} elevation={0} />
                            }}
                            title={moment(new Date()).format('D-MMM-YYYY')}
                            columns={[
                              {
                                title: 'Time Slot', field: 'fromTime', cellStyle: {
                                  verticalAlign: 'text-top'
                                },
                                render: rowData => {
                                  return (
                                    <span>{
                                      (rowData.fromTime && rowData.toTime)
                                        ?
                                        (moment(parseFloat(rowData.fromTime)).format('hh:mm A') + " to " + (moment(parseFloat(rowData.toTime)).format('hh:mm A')))
                                        :
                                        'NA'
                                    }</span>
                                  )
                                },
                              },
                              {
                                title: 'Capacity', field: 'limit', cellStyle: {
                                  verticalAlign: 'text-top'
                                },
                                render: rowData => {
                                  return (
                                    <span>{rowData.limit ? (rowData.limit + " Person") : 'NA'}</span>
                                  )
                                },
                              },
                              {
                                title: 'Booking Left', field: 'totalSeatsBooked', cellStyle: {
                                  verticalAlign: 'text-top'
                                },
                                render: rowData => {
                                  return (
                                    <span style={{ color: 'green' }}>{rowData.limit - rowData.totalSeatsBooked + " Left"}</span>
                                  )
                                },
                              },
                              {
                                title: 'Total Booked', field: 'totalSeatsBooked', cellStyle: {
                                  verticalAlign: 'text-top'
                                },
                                render: rowData => {
                                  return (
                                    <span>{rowData.totalSeatsBooked + " Booked"}</span>
                                  )
                                },
                              },
                            ]}
                            data={timeEntries[key]}

                            actions={[
                              {
                                icon: 'visibility',
                                tooltip: 'View Bookings',
                                onClick: (event, rowData) => window.location.replace(`/panel/bookings/${rowData.id}`),

                              }
                            ]}

                            options={{
                              headerStyle: {
                                backgroundColor: '#e0e0e0',
                                color: '#000'
                              },
                              toolbar: false,
                              actionsColumnIndex: -1,
                              paging: false,
                              search: false,
                              draggable: false,
                              sorting: false
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                )
              }
            })
          }
        </GridItem>
        <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
      </GridContainer>

    </React.Fragment >
  );
}