// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import ManageSlot from "views/Slot/ManageSlot";
import Profile from "views/Profile/Profile";
import ViewBooking from "views/Dashboard/ViewBooking";
import CreateBooking from "views/Dashboard/CreateBooking";

const dashboardRoutes = [
  {
    path: "/booking/search",
    name: "Search Booking",
    rtlName: "",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/panel"
  },
  {
    path: "/bookings",
    name: "View Bookings",
    rtlName: "",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/panel"
  },
  {
    path: "/bookings/:id",
    name: "View Bookings",
    rtlName: "",
    icon: Dashboard,
    component: ViewBooking,
    layout: "/panel"
  },
  {
    path: "/booking/create",
    name: "Create Booking",
    rtlName: "",
    icon: "content_paste",
    component: CreateBooking,
    layout: "/panel"
  },
  {
    path: "/slot",
    name: "Manage Slot",
    rtlName: "",
    icon: "content_paste",
    component: ManageSlot,
    layout: "/panel"
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "",
    icon: "content_paste",
    component: Profile,
    layout: "/panel"
  },
];

export default dashboardRoutes;
