import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MaterialTable, { MTableToolbar } from "material-table";
import { authenticationService } from '../../services/authentication'
import { notify } from 'react-notify-toast';
import moment, { months } from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
// import CardIcon from "components/Card/CardIcon.js";
import Paper from '@material-ui/core/Paper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './custom.css'
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = {
    typo: {
        // paddingLeft: "25%",
        marginBottom: "40px",
        position: "relative"
    },
    note: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        bottom: "10px",
        color: "#c0c1c2",
        display: "block",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "13px",
        left: "0",
        marginLeft: "20px",
        position: "absolute",
        width: "260px"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function ManageSlot(props) {
    const [data, setData] = useState([]);
    const [primaryStart, setPrimaryStart] = useState(moment().toDate());
    const [primaryEnd, setPrimaryEnd] = useState(moment(new Date()).add(6, 'months').toDate());
    const [isLoad, setIsLoad] = React.useState(false)
    const [enableCarryForward, setEnableCarryForwardsLoad] = React.useState(false)
    const [date, setDate] = React.useState(new Date())
    const [rangeStartDate, setRangeStartDate] = React.useState(new Date())
    const [rangeEndDate, setRangeEndDate] = React.useState(new Date())
    const [highlightDate, setHighlightDate] = React.useState([
        {
            "unavailable_date_style": []
        },
        {
            "not_applicable_date_style": []
        },
        {
            "available_date_style": []
        }
    ])
    const [activeTab, setActiveTab] = React.useState(0);

    React.useEffect(() => {
        setIsLoad(true)
        let startDate = moment().toDate();
        let endDate = moment(new Date()).add(6, 'months').toDate();
        setPrimaryStart(primaryStart)
        setPrimaryEnd(primaryEnd)
        return authenticationService.fetchDateStatus(startDate, endDate).then(
            async (result) => {
                let obj = result.response

                let not_applicable_date_arr = highlightDate.find(o => o.not_applicable_date_style)
                let available_date_arr = highlightDate.find(o => o.available_date_style)
                let unavailable_date_arr = highlightDate.find(o => o.unavailable_date_style)

                // grey
                let naDates = obj.UNAVAILABLE;
                naDates.map(item => {
                    let date = new Date(parseFloat(item))
                    console.log(date)
                    not_applicable_date_arr.not_applicable_date_style.push(date)
                })

                // green
                let remainingDates = obj.REMAINING;
                remainingDates.map(item => {
                    let date = new Date(parseFloat(item))
                    console.log(date)
                    available_date_arr.available_date_style.push(date)
                })

                // red
                let unavailableDates = obj.FULL;
                unavailableDates.map(item => {
                    let date = new Date(parseFloat(item))
                    console.log(date)
                    unavailable_date_arr.unavailable_date_style.push(date)
                })

                setHighlightDate([not_applicable_date_arr, available_date_arr, unavailable_date_arr])
                await handleDateChange(startDate)
                setIsLoad(false)

            }).catch((error) => {
                console.log(error)
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }, [])

    const fetchDates = async () => {
        return authenticationService.fetchDateStatus(primaryStart, primaryEnd).then(
            async (result) => {
                let obj = result.response

                let not_applicable_date_arr = highlightDate.find(o => o.not_applicable_date_style)
                let available_date_arr = highlightDate.find(o => o.available_date_style)
                let unavailable_date_arr = highlightDate.find(o => o.unavailable_date_style)

                // grey
                let naDates = obj.UNAVAILABLE;
                naDates.map(item => {
                    let date = new Date(parseFloat(item))
                    not_applicable_date_arr.not_applicable_date_style.push(date)
                })

                // green
                let remainingDates = obj.REMAINING;
                remainingDates.map(item => {
                    let date = new Date(parseFloat(item))
                    available_date_arr.available_date_style.push(date)
                })

                // red
                let unavailableDates = obj.FULL;
                unavailableDates.map(item => {
                    let date = new Date(parseFloat(item))
                    unavailable_date_arr.unavailable_date_style.push(date)
                })


                setHighlightDate([not_applicable_date_arr, available_date_arr, unavailable_date_arr])
                setIsLoad(false)

            }).catch((error) => {
                console.log(error)
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                console.log('error', error)
            })
    }

    const handleDateChange = (date) => {
        setIsLoad(true)
        setDate(date)
        setRangeEndDate(date)
        return authenticationService.fetchSlotByDate(date, date).then(
            (result) => {
                console.log('result.slots', result.slots)
                if (result.slots != {}) {
                    let key = Object.keys(result.slots)[0];
                    setData(result.slots[key])
                } else {
                    setData([])
                }
                setIsLoad(false)

            }).catch((error) => {
                console.log(error)
                setIsLoad(false)
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
            })
    };

    const onChange = dates => {
        const [start, end] = dates;
        setRangeStartDate(start);
        setRangeEndDate(end);
    };

    const handleTabChange = async (event, newValue) => {
        setActiveTab(newValue);
        // let startDate = new Date();
        // let endDate = new Date();

        // if (newValue == 1) {
        //   // this week
        //   endDate = moment().add(7, 'days').calendar();
        // } else if (newValue == 2) {
        //   // this month
        //   endDate = moment().add(1, 'months').calendar();
        // }

        // await fetchBookingForTheDate(startDate, new Date(endDate))
        // setIsLoad(false)
    };

    const addTimeSlot = (slot) => {
        if (Object.keys(slot).length == 0) {
            return
        }
        return authenticationService.addSlot(date, slot).then(
            (result) => {
                debugger
                setData([...data, result.savedSlot]);
                notify.show('New timeslot added.', 'success');
                setTimeout(() => {
                }, 500)
                return true
            }).catch((error) => {
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                return false
            })
    };

    const updateTimeSlot = (slot) => {
        let slotObj = {
            fromTime: slot.fromTime,
            toTime: slot.toTime,
            limit: parseInt(slot.limit),
            reportingTime: slot.reportingTime

        }
        return authenticationService.updateSlot(slot.id, slotObj).then(
            (result) => {
                notify.show('Timeslot updated.', 'success');
                setTimeout(() => {
                }, 500)
                return true
            }).catch((error) => {
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                return false
            })
    };

    const deleteTimeSlot = (slot) => {
        return authenticationService.deleteSlot(slot.id).then(
            async (result) => {
                await fetchDates()
                notify.show('Timeslot deleted.', 'success');
                setTimeout(() => {
                }, 500)
                return true
            }).catch((error) => {
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                return false
            })
    };

    const onCarryForwardClick = () => {
        let tommorrowDate = moment(date).add(1, 'day').toDate();
        return authenticationService.carryForwardSlot(date, tommorrowDate, rangeEndDate).then(
            (result) => {
                notify.show('success', 'success');
                setTimeout(() => {
                    window.location.reload()
                }, 500)
                return true
            }).catch((error) => {
                if (error == "TypeError: Failed to fetch") {
                    error = 'Internal server error'
                }
                notify.show(error, 'custom', 5000, {
                    background: '#F55A4E', text: "#FFFFFF"
                });
                return false
            })
    }

    const ExampleCustomInput = ({ value, onClick }) => (
        <Button onClick={onClick} color={"transparent"} className={`customFont`} style={{ fontSize: 15, border: '1px solid grey', height: 30 }}>
            {value}
        </Button>
    );

    const handleCEndDateChange = (date) => {
        setRangeEndDate(date)
    };
    console.log('data.length', data.length)
    return (
        <React.Fragment>
            {/* TAB */}
            <GridContainer align="center">
                <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
                <GridItem xs={0} sm={0} md={8} lg={8}>

                    {/* LOADER */}
                    <GridContainer align="center" style={{ paddingTop: isLoad ? 40 : 0 }}>
                        <GridItem xs={12} sm={4} md={4} lg={4}></GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                            {isLoad && <CircularProgress />}
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4} align="right"></GridItem>
                    </GridContainer>

                    {/* {!isLoad &&
                        <GridContainer align="left" style={{ paddingTop: 20 }}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={1}>
                                    <GridContainer align="left" style={{ padding: 10 }}>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <span style={{ marginRight: 10 }}>Date:</span>
                                            <DatePicker
                                                selected={date}
                                                onChange={handleDateChange}
                                                highlightDates={highlightDate}
                                                placeholderText="This highlight two ranges with custom classes"
                                                style={{
                                                    color: 'green'
                                                }}
                                                dateFormat="dd MMM yyyy"
                                                maxDate={moment(new Date()).add(6, 'months').toDate()}
                                            >
                                                <div>
                                                    <i class="fa fa-circle" style={{ color: 'green', marginLeft: 10 }}></i><span style={{ color: "green", padding: 5, fontSize: 12 }}>Slot Available</span>
                                                    <i class="fa fa-circle" style={{ color: 'red' }}></i><span style={{ color: "red", padding: 5, fontSize: 12 }}>Slot Unavailable</span>
                                                </div>
                                                <div>
                                                    <i class="fa fa-circle" style={{ color: 'grey', marginLeft: 10 }}></i><span style={{ color: "grey", padding: 5, fontSize: 12 }}>Not Applicable</span>
                                                    <i class="fa fa-circle" style={{ color: 'blue' }}></i><span style={{ color: "blue", padding: 5, fontSize: 12 }}>Current Selected</span>
                                                </div>
                                            </DatePicker>
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            </GridItem>
                        </GridContainer>} */}

                    {/* create slot */}

                    {!isLoad &&
                        <GridContainer align="left" style={{ paddingTop: 20 }}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <p>{`Select from date and to date to repeat slots`}</p>
                            </GridItem>
                        </GridContainer>
                    }

                    {!isLoad &&
                        <GridContainer align="left" style={{}}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Paper elevation={1}>
                                    <GridContainer align="left" style={{ padding: 10 }}>
                                        <GridItem xs={4} sm={4} md={4} lg={4}>
                                            <span style={{ marginRight: 10 }}>From Date:</span>
                                            <DatePicker
                                                selected={date}
                                                onChange={handleDateChange}
                                                style={{
                                                    padding: 10,
                                                    margin: 10,
                                                }}
                                                dateFormat="dd MMM yyyy"
                                                customInput={<ExampleCustomInput />}
                                            />
                                        </GridItem>
                                        <GridItem xs={4} sm={4} md={4} lg={4}>
                                            <span style={{ marginRight: 10 }}>To Date:</span>
                                            <DatePicker
                                                selected={rangeEndDate ? rangeEndDate : date}
                                                onChange={handleCEndDateChange}
                                                style={{
                                                    padding: 10,
                                                    margin: 10,
                                                }}
                                                minDate={date}
                                                dateFormat="dd MMM yyyy"
                                                maxDate={moment(date).add(3, 'months').toDate()}
                                                customInput={<ExampleCustomInput />}
                                            />
                                        </GridItem>

                                        <GridItem xs={4} sm={4} md={4} lg={4}>
                                            <Button
                                                variant="contained"
                                                color="#ffa726"
                                                disabled={(data.length > 0) ? false : true}
                                                onClick={onCarryForwardClick}
                                                style={{ marginTop: 10 }}
                                            >
                                                Submit
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            </GridItem>
                        </GridContainer>}


                    {/* RESULT */}
                    {
                        !isLoad &&
                        <Card style={{ marginTop: 30 }}>
                            <CardBody style={{ padding: '0px 15px 15px 15px' }}>

                                {/* TABLE VIEW */}
                                <GridContainer align="left">
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <MaterialTable
                                            columns={[
                                                {
                                                    title: "From Time",
                                                    field: "fromTime",
                                                    cellStyle: {
                                                        verticalAlign: 'text-top'
                                                    },
                                                    render: rowData => {
                                                        return (
                                                            <span>{rowData.fromTime ? moment(new Date(parseFloat(rowData.fromTime))).format('hh:mm A') : 'NA'}</span>
                                                        )
                                                    },
                                                    editComponent: editProps => (
                                                        <>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardTimePicker
                                                                    margin="normal"
                                                                    id="time-picker"
                                                                    value={editProps.value ? new Date(parseFloat(editProps.value)) : null}
                                                                    onChange={datetime => {
                                                                        let fullDate = moment(date).format('YYYY-MM-DD');
                                                                        let fullTime = moment(datetime).format('hh:mm:ss A');
                                                                        const constructedDate = moment(fullDate + ' ' + fullTime).toDate();
                                                                        editProps.onChange(new Date(constructedDate).getTime().toString())
                                                                    }}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change time',
                                                                    }}
                                                                    placeholder={'From time'}

                                                                />

                                                            </MuiPickersUtilsProvider>
                                                        </>
                                                    )
                                                },
                                                {
                                                    title: "To Time", field: "toTime",
                                                    cellStyle: {
                                                        verticalAlign: 'text-top'
                                                    },
                                                    render: rowData => {
                                                        return (
                                                            <span>{rowData.toTime ? moment(new Date(parseFloat(rowData.toTime))).format('hh:mm A') : 'NA'}</span>
                                                        )
                                                    },
                                                    editComponent: editProps => (
                                                        <>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardTimePicker
                                                                    margin="normal"
                                                                    id="time-picker"
                                                                    value={editProps.value ? new Date(parseFloat(editProps.value)) : null}
                                                                    onChange={datetime => {
                                                                        console.log('datetime', datetime)
                                                                        let fullDate = moment(date).format('YYYY-MM-DD');
                                                                        let fullTime = moment(datetime).format('hh:mm:ss A');
                                                                        console.log(fullDate, fullTime)
                                                                        const constructedDate = moment(fullDate + ' ' + fullTime).toDate();
                                                                        editProps.onChange(new Date(constructedDate).getTime().toString())
                                                                    }}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change time',
                                                                    }}
                                                                    placeholder={'To time'}

                                                                />

                                                            </MuiPickersUtilsProvider>
                                                        </>
                                                    )
                                                },
                                                {
                                                    title: "Limit", field: "limit", type: 'numeric',
                                                    cellStyle: {
                                                        verticalAlign: 'text-top', textAlign: 'left'
                                                    },
                                                    render: rowData => {
                                                        return (
                                                            <span>{rowData.limit ? rowData.limit : 'NA'}</span>
                                                        )
                                                    },
                                                    editComponent: editProps => (
                                                        <>
                                                            <TextField
                                                                id="standard-required"
                                                                defaultValue={editProps.value}
                                                                style={{
                                                                    marginTop: 15
                                                                }}
                                                                type={'number'}
                                                                onChange={(e) => editProps.onChange(e.target.value)}
                                                                placeholder={'Limit'}
                                                            />
                                                        </>
                                                    )
                                                },
                                                {
                                                    title: "Reporting Time", field: "reportingTime",
                                                    cellStyle: {
                                                        verticalAlign: 'text-top'
                                                    },
                                                    render: rowData => {
                                                        return (
                                                            <span>{rowData.reportingTime ? moment(new Date(parseFloat(rowData.reportingTime))).format('hh:mm A') : 'NA'}</span>
                                                        )
                                                    },
                                                    editComponent: editProps => (
                                                        <>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardTimePicker
                                                                    margin="normal"
                                                                    id="time-picker"
                                                                    value={editProps.value ? new Date(parseFloat(editProps.value)) : null}
                                                                    onChange={datetime => {
                                                                        let fullDate = moment(date).format('YYYY-MM-DD');
                                                                        let fullTime = moment(datetime).format('hh:mm:ss A');
                                                                        const constructedDate = moment(fullDate + ' ' + fullTime).toDate();
                                                                        editProps.onChange(new Date(constructedDate).getTime().toString())
                                                                    }}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change time',
                                                                    }}
                                                                    placeholder={'Reporting time'}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </>
                                                    )
                                                }
                                            ]}
                                            data={data}
                                            title={`Date: ${moment(date).format('DD-MMM-YYYY')}`}
                                            icons={{
                                                // Add: props => <AddIcon />,
                                                Edit: props => <EditIcon />,
                                                Delete: props => <DeleteIcon />,
                                                // Clear: props => <DeleteIcon />,
                                                Check: props => <CheckIcon />,
                                                Search: props => <SearchIcon />,
                                                ResetSearch: props => <DeleteIcon />
                                            }}
                                            editable={{
                                                onRowAdd: newData =>
                                                    new Promise(async (resolve, reject) => {
                                                        try {
                                                            let isAdded = await addTimeSlot(newData)
                                                            if (!isAdded) {
                                                                reject()
                                                                return
                                                            }
                                                            resolve();
                                                        } catch (e) {
                                                            reject()
                                                        }
                                                    }),
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise(async (resolve, reject) => {
                                                        try {
                                                            let isUpdated = await updateTimeSlot(newData)
                                                            if (!isUpdated) {
                                                                reject()
                                                                return
                                                            }
                                                            const dataUpdate = [...data];
                                                            const index = oldData.tableData.id;
                                                            dataUpdate[index] = newData;
                                                            setData([...dataUpdate]);
                                                            resolve();
                                                        } catch (e) {
                                                            reject()
                                                        }
                                                    }),
                                                onRowDelete: oldData =>
                                                    new Promise(async (resolve, reject) => {
                                                        try {
                                                            let isDeleted = await deleteTimeSlot(oldData)
                                                            if (!isDeleted) {
                                                                reject()
                                                                return
                                                            }
                                                            const dataDelete = [...data];
                                                            const index = oldData.tableData.id;
                                                            dataDelete.splice(index, 1);
                                                            setData([...dataDelete]);
                                                            resolve();
                                                        } catch (e) {
                                                            reject()
                                                        }
                                                    })
                                            }}
                                            components={{
                                                Container: props => <Paper {...props} elevation={0} />,
                                                // Toolbar: props => (
                                                //     <div style={{ backgroundColor: '#e8eaf5' }}>
                                                //         <MTableToolbar {...props} />
                                                //     </div>
                                                // )
                                            }}
                                            options={{
                                                paging: false,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: '#e0e0e0',
                                                    color: '#000',
                                                    textAlign: 'left'
                                                },
                                                search: false,
                                                sorting: false,
                                                draggable: false,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    }
                </GridItem >
                <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
            </GridContainer >

        </React.Fragment >
    );
}