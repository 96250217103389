import React from "react";
import ReactDOM from "react-dom";
import Notifications, { notify } from 'react-notify-toast';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { currentUserSubject } from "./consonants";
import { Offline, Online } from "react-detect-offline";
import NoInternet from './views/NoInternet/NoInternet'
import { CookiesProvider } from 'react-cookie';

// core components
import Admin from "layouts/Admin.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import Login from "views/Login/Login";

const hist = createBrowserHistory();

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUserSubject.value ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !currentUserSubject.value ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/panel",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

ReactDOM.render(
  <React.Fragment>
    <CookiesProvider>
      {/* <Online> */}
      <Notifications />
      <Router history={hist}>
        <Switch>
          <PublicRoute path="/login">
            <Login />
          </PublicRoute>
          <PrivateRoute path="/panel">
            <Admin path="/panel" />
          </PrivateRoute>
          <Redirect from="/" to="/panel" />
        </Switch>
      </Router>
      {/* </Online>
      <Offline>
      <NoInternet />
      </Offline> */}
    </CookiesProvider>
  </React.Fragment>,
  document.getElementById("root")
);
